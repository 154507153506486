import { render, staticRenderFns } from "./Wolt.vue?vue&type=template&id=4083f4f2&scoped=true"
import script from "./Wolt.vue?vue&type=script&lang=js"
export * from "./Wolt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4083f4f2",
  null
  
)

export default component.exports