import { render, staticRenderFns } from "./WoltComponent.vue?vue&type=template&id=a45cc598&scoped=true"
import script from "./WoltComponent.vue?vue&type=script&lang=js"
export * from "./WoltComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a45cc598",
  null
  
)

export default component.exports