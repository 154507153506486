<template>
	<div>
		<page-title heading="WOLT Delivery" subheading="WOLT Delivery" :icon="icon"></page-title>
		<div class="app-main__inner">
			<WoltComponent />
		</div>
	</div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import WoltComponent from "@/components/settings/moduleSettings/wolt/WoltComponent";
export default {
	name: "Wolt",
	components: { WoltComponent, PageTitle },
	data() {
		return {
			icon: "pe-7s-plane icon-gradient bg-tempting-azure",
		};
	},
};
</script>

<style scoped></style>
