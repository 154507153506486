<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card>
					<v-row>
						<v-col cols="12">
							<v-img
								height="150"
								src="@/assets/images/wolt.png"
								contain
								style="margin-top: 30px; margin-bottom: 30px"
							></v-img>
						</v-col>
					</v-row>

					<v-toolbar dark color="primary" flat>
						<v-toolbar-title>{{ $t("settings.lang_woltInterface") }}</v-toolbar-title>

						<v-spacer></v-spacer>

						<v-btn icon>
							<v-icon>refresh</v-icon>
						</v-btn>
					</v-toolbar>

					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="apiKey"
									:disabled="loading"
									outlined
									dense
									:label="$t('settings.lang_apiKey')"
								></v-text-field>
								<v-text-field
									v-model="venueId"
									:disabled="loading"
									outlined
									dense
									:label="$t('settings.lang_woltVenueId')"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">{{
							$t("generic.lang_save")
						}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VofficeAPIkey from "@/components/settings/moduleSettings/voffice/VofficeAPIkey";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
	name: "WoltComponent",
	components: { VofficeAPIkey },
	data() {
		return {
			loading: false,
			venueId: "",
			apiKey: "",
		};
	},
	mounted() {
		this.getInfos();
	},
	methods: {
		getInfos() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.SETTINGS.MODULESETTINGS.WOLT.GET)
				.then((res) => {
					console.log(res.data);
					if (res.data.status === "SUCCESS") {
						this.venueId = res.data.wolt_venue_id;
						this.apiKey = res.data.wolt_api_key;
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		saveSettings() {
			this.loading = true;

			this.axios
				.post(ENDPOINTS.SETTINGS.MODULESETTINGS.WOLT.UPDATE, {
					wolt_venue_id: this.venueId,
					wolt_api_key: this.apiKey,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped></style>
